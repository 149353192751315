import React from "react"
import { Container, Content, Headline, StyledLink, Subtitle } from "./styles"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"
import { StaticImage } from "gatsby-plugin-image"
import { InfoBtn } from "../mac-board/styles"

const Laptop = () => {
  const intl = useIntl()
  return (
    <Container>
      <StyledLink to="/notebook" style={{ color: "white" }}>
        <Content>
          <Headline>{intl.formatMessage(i18n.cards.titles.notebook)}</Headline>
          <Subtitle>{intl.formatMessage(i18n.cards.texts.notebook)}</Subtitle>
          <InfoBtn>
            {intl.formatMessage(i18n.buttons.additionalInformation)}
          </InfoBtn>
          <StaticImage
            src="../../../../images/laptop.jpeg"
            alt="notebook"
            placeholder="blurred"
            height={1000}
            width={3000}
          />
        </Content>
      </StyledLink>
    </Container>
  )
}

export default Laptop
