import React from "react"
import { Image } from "../../../common/shared-components/images"
import {
  Address,
  Box,
  Button,
  Container,
  Content,
  Header,
  Holder,
  ImgHolder,
  Text,
  TextHolder,
  Title,
  Th,
  Time,
  Direction,
  Table,
  TextColor,
  Line,
  Phone,
} from "./styles"
import StyledBackgroundSection from "./background"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const Location = () => {
  return (
    <Container>
      <Content direction="column">
        <ImgHolder>
          <StyledBackgroundSection />
        </ImgHolder>
        <TextHolder>
          <TextBox />
        </TextHolder>
      </Content>
    </Container>
  )
}

const TextBox = () => {
  const intl = useIntl()
  return (
    <Holder>
      <Title>{intl.formatMessage(i18n.shops.headline)} </Title>
      <Header>{intl.formatMessage(i18n.shops.inns.title)}</Header>
      <Text>
        {intl.formatMessage(i18n.shops.inns.desc, {
          break: str => (
            <span>
              <br />
              <br />
              {str}{" "}
            </span>
          ),
        })}
      </Text>
      <Button>{intl.formatMessage(i18n.shops.inns.termin)}</Button>
      <Phone>{intl.formatMessage(i18n.shops.inns.phone)}</Phone>
      <Box>
        <Time>
          <TextColor>
            {intl.formatMessage(i18n.shops.inns.openTime.headline)}{" "}
          </TextColor>
          <Table>
            <tbody>
              <tr>
                <Th>{intl.formatMessage(i18n.shops.inns.openTime.day.mon)}</Th>
                <Th>{intl.formatMessage(i18n.shops.inns.openTime.time.mon)}</Th>
              </tr>
              <tr>
                <Th>{intl.formatMessage(i18n.shops.inns.openTime.day.fri)}</Th>
                <Th>{intl.formatMessage(i18n.shops.inns.openTime.time.fri)}</Th>
              </tr>
              <tr>
                <Th>{intl.formatMessage(i18n.shops.inns.openTime.day.sat)}</Th>
                <Th>{intl.formatMessage(i18n.shops.inns.openTime.time.sat)}</Th>
              </tr>
            </tbody>
          </Table>
        </Time>
        <Line />
        <Direction>
          <TextColor>
            {intl.formatMessage(i18n.shops.inns.direction.title)}
          </TextColor>
          <Table>
            <tbody>
              <tr>
                <Th>
                  <div
                    style={{
                      width: "25px",
                      marginRight: ".3rem",
                      filter: "grayscale(100%)",
                    }}
                  >
                    <Image source="sbahn.png" alt="" />
                  </div>
                </Th>
                <Th>
                  {intl.formatMessage(i18n.shops.inns.direction.info.sbahn)}
                </Th>
              </tr>
              <tr>
                <Th>
                  <div
                    style={{
                      width: "25px",
                      marginRight: ".3rem",
                      filter: "grayscale(100%)",
                    }}
                  >
                    <Image source="ubahn.png" alt="" />
                  </div>
                </Th>
                <Th>
                  {intl.formatMessage(i18n.shops.inns.direction.info.ubahn)}
                </Th>
              </tr>
              <tr>
                <Th>
                  <div
                    style={{
                      width: "25px",
                      marginRight: ".3rem",
                      filter: "grayscale(100%)",
                    }}
                  >
                    <Image source="bus.png" alt="" />
                  </div>
                </Th>
                <Th>
                  {intl.formatMessage(i18n.shops.inns.direction.info.bus)}
                </Th>
              </tr>
            </tbody>
          </Table>
        </Direction>
      </Box>
      <Address>{intl.formatMessage(i18n.shops.inns.address)} </Address>
    </Holder>
  )
}

export default Location
