import styled from "styled-components"

export const UpsBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: #2b2418;
  color: white;
  text-align: center;
`
export const Content = styled.div`
 
  max-width: 1600px;
  margin 0 auto;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 768px){
    flex-direction: column;
    padding: 0;
  }
`

export const Title = styled.h1`
  font-family: "Open Sans";
  color: white;
  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`
export const TitleLive = styled(Title)`
  margin-top: -2.9rem;
  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`

export const SubTitle = styled.p`
  font-family: "Open Sans";
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
`

export const TextBox = styled.p`
  font-family: "Open Sans";
  font-size: 1.2rem;
  font-weight: 300;
  min-width: 300px;
  max-width: 550px;
  margin: 1em auto;
  padding: 0 2.5rem;
`
export const Iphone = styled.div`
  width: 350px;
  margin: 0 auto -5rem 9rem;
  @media (max-width: 768px) {
    margin: 0 auto -3rem 3.7rem;
  }
`

export const Line = styled.div`
  height: 300px;
  border-right: 1px solid white;
  @media (max-width: 768px) {
    display: none;
  }
`
export const HLine = styled.div`
  width: 300px;
  border-bottom: 1px solid white;
  margin: 2rem auto;
  @media (min-width: 769px) {
    display: none;
  }
`

export const Price = styled.div`
  font-family: "Open Sans";
  font-size: 1.5rem;
  border: 1px solid #fec560;
  background: #fec560;
  color: black;
  width: 180px;
  margin: 1em auto;
  border-radius: 5px;
  padding: 0.3rem;
`

export const SendBox = styled.div`
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`

export const LiveBox = styled.div`
  padding: 0 3rem;
  margin-top: 1rem;
`
