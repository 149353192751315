import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background-color: #eee;
  color: black;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  max-width: 1700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 1.5rem;
  }
`

export const TextBox = styled.div`
  box-sizing: border-box;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 1.5rem;
  }
`

export const ImgBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem;
`

export const HeaderBox = styled.div`
  box-sizing: border-box;
  width: 50%;
  @media (max-width: 768px) {
    width: 100vw;
    border: none;
  }
`

export const Img = styled.div`
  width: 200px;
  padding: 1rem 2rem;
`

export const Title = styled.h3`
  width: 100%;
  font-family: "Open Sans";
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 2rem;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`
export const Header = styled.h3`
  color: black;
  font-family: "Open Sans";
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-top: 2rem;
  }
`

export const Text = styled.p`
  font-family: "Open Sans";
  font-weight: 300;
  max-width: 500px;
  color: black;
  font-size: 1.2rem;
`
export const Line = styled.div`
  border-right: 1px solid black;
  height: 200px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const Horizontal = styled.div`
  border-bottom: 1px solid black;
  width: 400px;
  margin: 0 auto;
  @media (min-width: 768px) {
    display: none;
  }
`
