import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

export const Container = styled.div`
  width: 100%;
  background: #eee;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  max-width: 1750px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Holder = styled.div`
  width: 100%;
  text-align: center;
  padding: 3rem 5rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`

export const Header = styled.h1`
  font-family: "Open Sans";
  color: black;
  margin: 1rem auto 0.1rem auto;
  font-size: 2.5rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

export const Subtitle = styled.h2`
  font-family: "Open Sans";
  font-size: 1.3rem;
  margin: 0.9rem 0;
  color: black;
  font-weight: 300;
  line-height: 2rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
    font-size: 1.3rem;
  }
`

export const StyledLink = styled(Link)`
  font-family: "Open Sans";
  text-decoration: none;
  color: #2295ff;
`
export const InfoBtn = styled.div`
  font-family: "Open Sans";
  text-decoration: none;
  color: #0066cc;
`

export const ImgHolder = styled.div`
  margin: 2rem auto;
  width: auto;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100%;
    margin: 2rem auto 0 auto;
  }
`
