import React from "react"
import styled from "styled-components"

import { useIntl, Link } from "gatsby-plugin-react-intl"
import i18n from "../i18n"
import StyledBackgroundSection from "./background"

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #eee;
`

const Content = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
`

const Title = styled.h3`
  font-family: "Open Sans";
  color: black;
`

const Text = styled.p`
  font-family: "Open Sans";
  color: black;
  font-weight: 300;
`

const Button = styled.button`
  font-family: "Open Sans";
  outline: none;
  border: none;
  cursor: pointer;
  background: #2997ff;
  color: white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 300;
  &:hover {
    color: black;
    background: #333;
  }
`

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`

const TextHolder = styled.div`
  max-width: 1000px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 0 4rem;
  @media (max-width: 1024px) {
    padding: 1rem 1.5rem;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

const About = () => {
  const intl = useIntl()
  return (
    <Container>
      <StyledBackgroundSection />
      <Content>
        <TextHolder>
          <Title>{intl.formatMessage(i18n.aboutUs.text.textOne)}</Title>
          <Text>{intl.formatMessage(i18n.aboutUs.text.textTwo)}</Text>
          <Button>
            {intl.formatMessage(i18n.aboutUs.button, {
              click: str => (
                <span>
                  <StyledLink to="/ueber-uns">{str}</StyledLink>
                </span>
              ),
            })}
          </Button>
        </TextHolder>
      </Content>
    </Container>
  )
}

export default About
