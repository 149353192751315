import styled from "styled-components"

export const StatsContainer = styled.div`
  display: flex;
  font-family: "Open Sans";
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  background: #4c4c4c;
  color: #f5f5f7;
  margin: 1rem auto;

  @media (max-width: 950px) {
    flex-flow: row wrap;
  }
`

export const StatsElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  width: 90%;
  @media (min-width: 768px) {
    height: 250px;
  }
`

export const StatsNumber = styled.div`
font-family: "Open Sans";
  text-align: center;
  font-size: 2rem;
  margin: 0.5rem;
`

export const StatsText = styled.p`
font-family: "Open Sans";
  text-align: center;
  font-size: .9rem;
  width: 100%;
`

export const ElementDesign = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
`
