import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #eee;
  color: black;
  margin-top: 1rem;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: ${props => props.direction};
  }
`

export const Title = styled.h1`
  font-family: "Open Sans";
  text-align: center;
  font-weight: 400;
  margin-top: -2rem;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    margin: 1rem auto;
  }
`

export const ImgHolder = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 768px) {
    width: 100vw;
  }
`

export const TextHolder = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Holder = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 2rem;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Header = styled.h2`
  font-family: "Open Sans";
  text-align: center;
  font-weight: 300;
  font-size: 2rem;
  margin: 1.5rem 0;
  width: 100%;
  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`

export const HeaderDesign = styled.span`
  font-family: "Open Sans";
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 300;
  display: block;
  color: #f36c2a;
`

export const Text = styled.p`
  font-family: "Open Sans";
  margin: 1rem auto;
  text-align: center;
  font-weight: 300;
`
export const TextColor = styled(Text)`
  color: black;
  text-transform: uppercase;
`

export const Button = styled.div`
  font-family: "Open Sans";
  border: 1px solid #03989e;
  background: #00777d;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  max-width: 300px;
  margin: 2rem auto;
  text-align: center;
  text-transform: uppercase;
  color: white;
`
export const Btn = styled(Button)`
  background: #c33b38;
  border: 1px solid #c33b38;
`

export const Phone = styled.div`
  font-family: "Open Sans";
  text-align: center;
  font-size: 1.6rem;
  font-weight: 300;
  margin: 1rem;
`

export const Box = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

export const Address = styled.p`
  font-family: "Open Sans";
  margin: 2rem auto 1rem auto;
  text-align: center;
  font-weight: 300;
`
export const Direction = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Time = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Th = styled.th`
  font-size: 1.2rem;
  text-align: left;
  font-weight: 100;
  border: none;
  padding: 0.1rem;
  @media (max-width: 768px) {
    &:last-child {
      text-align: right;
    }
  }
`

export const Table = styled.table`
  width: 240px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`

export const Line = styled.div`
  border-left: 1px solid white;
  margin-right: 15px;
  height: 110px;
  @media (max-width: 768px) {
    display: none;
  }
`
