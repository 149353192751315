import React from "react";
import GoogleMapReact from "google-map-react";
import "./styles.js";

import { GoogleMap, PinText, Point } from "./styles.js";
const Map = ({ zoomLevel }) => {
  return (
    <>
      <GoogleMap>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDu4zgTNbN5YpvAkgWuRFWmiNq2kh8CnW4",
          }}
          defaultCenter={{ lat: 52.4873473764321, lng: 13.330965187252863 }}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat="52.47907336395168"
            lng="13.344085355575688"
            text="TMC-TEC Schöneberg"
            link="https://goo.gl/maps/NySDqyH7JjA9jsnw6"
          />

          <LocationPin
            lat="52.49961261342466"
            lng="13.302951835040188"
            text="TMC-TEC Ku Damm"
            link="https://goo.gl/maps/wVuurFYux7nPewRK6"
          />
        </GoogleMapReact>
      </GoogleMap>
    </>
  );
};

const LocationPin = ({ text, link }) => (
  <PinText href={link} target="_blank" rel="noreferrer nofollow">
    {text}
    <Point />
  </PinText>
);

export default Map;
