import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #eee;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 2rem auto;

`

export const Text = styled.p`
  font-family: "Open Sans";
  margin: 2rem auto;
  text-align: center;
  
  @media(max-width: 768px){
    padding: 0 1.5rem;
  }
`

export const Img = styled.div`
  width: 200px;
`

export const ImgHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`
export const LogoMobile = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`