import React from "react"
import { Image } from "../../../../common/shared-components/images"
import { Container, Content, Text, ImgHolder, Img, LogoMobile } from "./styles"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../../i18n"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

const LOGOS = [
  "lufthansa.png",
  "siemens.png",
  "rtl.png",
  "continental.png",
  "braun.png",
  "osram.png",
]

const Company = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <Text>{intl.formatMessage(i18n.aboutUs.footer)}</Text>
        <ImgHolder>
          <Carousel
            infinite
            autoPlay={2500}
            animationSpeed={2000}
            slidesPerPage={3}
          >
            {LOGOS.map((logo, key) => (
              <Img key={key}>
                <Image source={logo} alt={logo} />
              </Img>
            ))}
          </Carousel>
        </ImgHolder>
        <Slider />
      </Content>
    </Container>
  )
}

const Slider = () => {
  return (
    <Carousel infinite autoPlay={2500} animationSpeed={2000}>
      {LOGOS.map((logo, key) => (
        <LogoMobile key={key} style={{ width: "140px" }}>
          <Image source={logo} alt={logo} />
        </LogoMobile>
      ))}
    </Carousel>
  )
}

export default Company
