import React from "react"
import { Image } from "../../../common/shared-components/images"
import {
  Container,
  Content,
  Header,
  Holder,
  ImgHolder,
  InfoBtn,
  StyledLink,
  Subtitle,
} from "./styles"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const MacBoard = () => {
  const intl = useIntl()

  return (
    <Container>
      <Content>
        <Card
          header={intl.formatMessage(i18n.cards.titles.macbook)}
          subtitle={intl.formatMessage(i18n.cards.texts.macbook)}
          btnText={intl.formatMessage(i18n.buttons.additionalInformation)}
          source="mac.jpeg"
          alt={intl.formatMessage(i18n.cards.titles.macbook)}
          page="/macbook"
        />

        <Card
          header={intl.formatMessage(i18n.cards.titles.board)}
          subtitle={intl.formatMessage(i18n.cards.texts.board)}
          btnText={intl.formatMessage(i18n.buttons.additionalInformation)}
          source="soldering.jpeg"
          alt={intl.formatMessage(i18n.cards.titles.board)}
          page="/board"
        />
      </Content>
    </Container>
  )
}

const Card = ({ header, subtitle, btnText, source, alt, page }) => {
  return (
    <Holder>
      <StyledLink to={page}>
        <Header>{header}</Header>
        <Subtitle>{subtitle}</Subtitle>
        <InfoBtn>{btnText}</InfoBtn>
        <ImgHolder>
          <Image source={source} alt={alt} />
        </ImgHolder>
      </StyledLink>
    </Holder>
  )
}

export default MacBoard
