import React from "react";

import { ContentRow } from "../components/common/shared-components/layouts";
import Page from "../components/common/page";
import Stats from "../components/page-components/landing/stats";
import Map from "../components/page-components/landing/map";
import UpsContainer from "../components/page-components/landing/ups";
import Company from "../components/page-components/landing/about-us/companies";
import About from "../components/page-components/landing/hero-image";

import Laptop from "../components/page-components/landing/intro";
import MacBoard from "../components/page-components/landing/mac-board";
import Location from "../components/page-components/landing/location";
import LocationDamaske from "../components/page-components/landing/location/indexDamaske";
import IpcPartner from "../components/page-components/landing/ipc-partner";

const CONTENT_ROWS = [
  [<Laptop />],
  [<MacBoard />],
  [<Company />],
  [<UpsContainer />],
  [<Location />],
  [<LocationDamaske />],
  [<Map zoomLevel={13} />],
  [<About />],
  [<Stats />],
  [<IpcPartner />],
];

const IndexPage = () => (
  <Page title="landing">
    {CONTENT_ROWS.map((components, key) => (
      <ContentRow key={key} components={components} />
    ))}
  </Page>
);

export default IndexPage;
