import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useIntl, Link } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

import BackgroundImage from "gatsby-background-image"
import SOLDA_LOGO from "../../../../logos/solda-logo-white.svg"

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "berlin.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid
  const intl = useIntl()
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#243780`}
    >
      <Hero src={SOLDA_LOGO} alt="Solda logo" />
      <TextHolder>
        <Title>{intl.formatMessage(i18n.aboutUs.text.textOne)}</Title>
        <Text>{intl.formatMessage(i18n.aboutUs.text.textTwo)}</Text>
        <Button>
          {intl.formatMessage(i18n.aboutUs.button, {
            click: str => (
              <span>
                <StyledLink to="/ueber-uns">{str}</StyledLink>
              </span>
            ),
          })}{" "}
        </Button>
      </TextHolder>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  user-select: text;
  width: 100vw;
  height: 70vh;
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1024px) {
    height: 500px;
    width: 100vw;
    background-position: top;
  }

  @media (max-width: 768px) {
    height: 300px;
    background-position: top;
  }
`
const Hero = styled.img`
  user-select: none;
  width: 120px;
  display: flex;
  justify-content: flex-start;
  padding: 2rem 2rem 2rem 4rem;
  @media (max-width: 1024px) {
    padding-left: 1rem;
    width: 120px;
  }
`
const TextHolder = styled.div`
  max-width: 1000px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 0 4rem;
  @media (max-width: 1024px) {
    display: none;
  }
`

const Title = styled.h3`
  font-family: "Open Sans";
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
`

const Text = styled.p`
  font-family: "Open Sans";
  color: black;
  width: 100%;
  max-width: 600px;
  font-weight: 300;
  font-size: 1.2rem;
`

const Button = styled.button`
  font-family: "Open Sans";
  outline: none;
  border: 1px solid #2997ff;
  color: black;
  background: #2997ff;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  &:hover {
    border: 2px solid #2997ff;
    background: #333;
  }
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-family: "Open Sans";
  font-weight: 300;
`

export default StyledBackgroundSection
