import React, { useRef } from "react";
import {
  StatsNumber,
  StatsText,
  StatsElement,
  StatsContainer,
  ElementDesign,
} from "./styles";
import { IoIosPeople } from "react-icons/io";
import { MdDevicesOther } from "react-icons/md";
import { GiCoffeeCup, GiRibbonMedal } from "react-icons/gi";
import { FaPeopleCarry } from "react-icons/fa";
import { AiOutlinePercentage } from "react-icons/ai";
import { Spring } from "react-spring/renderprops";
import useVisibilitySensor from "@rooks/use-visibility-sensor";

export const MainStatsContainer = ({ stats }) => {
  return (
    <StatsContainer>
      {stats.map((stat, key) => (
        <ElementDesign key={key}>
          <Elements
            icon={stat.icon}
            text={stat.text}
            statNumber={stat.number}
          />
        </ElementDesign>
      ))}
    </StatsContainer>
  );
};

export const Elements = ({ icon, text, statNumber }) => {
  const rootNode = useRef(null);
  const { isVisible } = useVisibilitySensor(rootNode, {
    intervalCheck: true,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
  });
  return (
    <StatsElement ref={rootNode}>
      {icon}
      <StatsNumber>
        {isVisible && (
          <Spring
            config={{ precision: 1, tension: 280, friction: 120 }}
            from={{ number: 0 }}
            to={{ number: statNumber }}
          >
            {(props) => <div>{props.number.toFixed(0)}</div>}
          </Spring>
        )}
      </StatsNumber>
      <StatsText>{text}</StatsText>
    </StatsElement>
  );
};

const STATS = [
  {
    text: "Zufriedene Kunden",
    number: 23700,
    icon: <IoIosPeople size={70} />,
  },
  {
    text: "Mon. Rep. Geräte",
    number: 780,
    icon: <MdDevicesOther size={70} />,
  },
  {
    text: "Servicepartner",
    number: 186,
    icon: <FaPeopleCarry size={70} />,
  },
  {
    text: "Erfolgsquote",
    number: 91,
    icon: <AiOutlinePercentage size={70} />,
  },
  {
    text: "Jahre Erfahrung",
    number: 14,
    icon: <GiRibbonMedal size={70} />,
  },
  {
    text: "Liter Kaffee am Tag",
    number: 4,
    icon: <GiCoffeeCup size={70} />,
  },
];

const Stats = () => {
  return <MainStatsContainer stats={STATS} />;
};

export default Stats;
