import React from "react";
import { Image } from "../../../common/shared-components/images";

import UPS_LOGO from "../../../../logos/UPS_logo.svg";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

import {
  Content,
  Iphone,
  HLine,
  LiveBox,
  Line,
  Price,
  SendBox,
  SubTitle,
  TextBox,
  Title,
  TitleLive,
  UpsBox,
} from "./styles";
import { StyledLink } from "../mac-board/styles";

const Ups = () => {
  const intl = useIntl();

  return (
    <UpsBox>
      <Content>
        <SendBox>
          <Title>
            {intl.formatMessage(i18n.ups.upsHeadline, {
              color: (str) => <span style={{ color: "#FFD484" }}>{str}</span>,
            })}
          </Title>
          <SubTitle>{intl.formatMessage(i18n.ups.upsSubHeadline)} </SubTitle>
          <TextBox>
            {intl.formatMessage(i18n.ups.upsText, {
              break: (str) => (
                <span>
                  <br />
                  <br />
                  {str}
                </span>
              ),
            })}
          </TextBox>

          <img src={UPS_LOGO} alt="ups logo" width="150px" />

          <StyledLink to="/process-send">
            <Price>{intl.formatMessage(i18n.ups.upsPrice)} </Price>{" "}
          </StyledLink>
        </SendBox>

        <Line />
        <HLine />
        <LiveBox>
          <TitleLive>
            {intl.formatMessage(i18n.ups.repair, {
              color: (str) => <span style={{ color: "#00C2CB" }}>{str}</span>,
            })}
          </TitleLive>

          <TextBox>
            {intl.formatMessage(i18n.ups.repairText, {
              break: (str) => (
                <span>
                  <br />
                  <br />
                  {str}
                </span>
              ),
            })}
          </TextBox>

          <Iphone>
            <Image source="iphone-hand.png" alt="iphone in hand" />
          </Iphone>
        </LiveBox>
      </Content>
    </UpsBox>
  );
};

export default Ups;
