import styled from "styled-components";
import { Link } from "gatsby-plugin-react-intl";

export const Container = styled.div`
  width: 100%;
  background-color: #030000;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  max-width: 1200px;
  margin: 6rem auto 0 auto;
`;

export const Headline = styled.h1`
  font-size: 2.5rem;
  font-family: "Open Sans";
  margin: 1rem auto 0.1rem auto;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.h2`
  font-family: "Open Sans";
  margin: 0.9rem 0;
  font-size: 1.5rem;
  font-weight: 300;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
    font-size: 1.3rem;
  }
`;

export const StyledLink = styled(Link)`
  font-family: "Open Sans";
  text-decoration: none;
  color: #2295ff;
`;
