import styled from "styled-components"

export const GoogleMap = styled.div`
  width: 100%;
  height: 450px;
  user-select: text;
  margin: 1rem auto;
`

export const PinText = styled.a`
  font-size: 0.9rem;
  text-decoration: none;
  width: 4rem;
  color: #F36C2A;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  

`

export const Point = styled.div`
   {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    background-color: #F36C2A;
    border-radius: 50%;
    position: relative;
    margin: 0.2rem 0;

    &:before {
      content: "";
      width: inherit;
      height: inherit;
      border: 5px solid #F36C2A;
      border-radius: inherit;
      position: absolute;
      z-index: -10;
      opacity: 0;
      animation: 2s expand cubic-bezier(0.29, 0, 0, 1) infinite;
      animation-delay: 1s;
    }
  }

  @keyframes expand {
    /* Starting at 1 to prevent IOS flicker */
    1% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      width: 750%;
      height: 750%;
      opacity: 0;
      border-color: #fff;
      background-color: none;
      border-width: 2px;
    }
  }
`
