import React from "react"
import { Image } from "../../../common/shared-components/images"
import {
  Container,
  Content,
  ImgBox,
  Text,
  TextBox,
  Title,
  Img,
  Header,
  HeaderBox,
  Line,
  Horizontal,
} from "./styles"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const IpcPartner = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <TextBox>
          <Title>{intl.formatMessage(i18n.aboutUs.title)} </Title>
          <Text>{intl.formatMessage(i18n.aboutUs.subtitle)}</Text>
          <Img>
            <Image source="Logo_logo.png" alt="ipc logo" />
          </Img>
        </TextBox>
        <Line />
        <Horizontal />
        <HeaderBox>
          <Header>{intl.formatMessage(i18n.partner.title)}</Header>
          <ImgBox>
            <Img>
              <Image
                source="electronic-partner.png"
                alt="electronic partner logo"
              />
            </Img>
            <Img>
              <Image source="com-team-color.png" alt="com team logo" />
            </Img>
          </ImgBox>
        </HeaderBox>
      </Content>
    </Container>
  )
}

export default IpcPartner
